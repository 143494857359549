.page-content {
  max-width: 900px;
  margin: 0em auto;
  padding: 2em;
}

.masthead-site-title {
  text-transform: lowercase !important;
  font-family: $headline-font-family !important;
  // Don't use !important, otherwise you'll kill the shrinking effect
  font-size: 2.5rem;
}
