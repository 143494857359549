.page-heading {
  font-size: 4rem;
}

.post-list {
  list-style: none;
  padding-left: 0;

  li {
    margin-top: 4rem;
  }

  .post-title {
    margin-top: 0;
    font-size: 3rem;
  }

  .post {
    font-size: 1.5rem;
    margin-top: 2rem;
  }
}

.post-content {
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  grid-gap: 1em;

  font-size: 2rem;

  // Reset
  * {
    margin: 0;
    padding: 0;
    font-size: 100%;
  }

  %heading-vertical-rhythm {
    // ADDITIONAL vertical space
    margin-top: 0.5em;
  }

  h1, h2, h3, h4, h5, h6 {
    @extend %heading-vertical-rhythm;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: $headline-font-family;
    font-weight: bold;
  }

  h1 { font-size: 2.5em; }
  h2 { font-size: 2em; }
  h3 { font-size: 1.5em; }
  h4, h5, h6 { font-size: 1.2em; }

  ol, ul {
    list-style-position: outside;
    padding-left: 5rem;
    margin-left: 0;
    text-indent: 0;
  }

  %callout {
    $callout-text-color: black;
    $callout-link-text-color: darken($brand-color, 20%);

    background-color: lighten($brand-color, 25%);
    color: $callout-text-color;
    // I want to be able to see the links!
    a, a:visited {
      color: $callout-link-text-color;
    }
    margin: 1em;
    padding: 1em;
  }

  aside, blockquote {
    @extend %callout;

    // Avoid double vertical spacing
    :first-child { margin-top: 0; }
  }

  img {
    max-width: 96%;
    margin: auto;
  }

  .footnotes {
    ol {
      padding-left: 3rem;
      margin-left: 0;
      text-indent: 0;
      list-style-position: outside;
    }
    * { font-size: 2rem; }
  }

  pre code { color: inherit; }
  code {
    color: darken($brand-color, 20%);
  }

  pre.sourceCode { padding: 1rem; }

  figure {
    display: flex;
    flex-direction: column;
    width: 80%;

    margin-left: auto;
    margin-right: auto;

		figcaption {
  		font-size: 80%;
  		justify-content: center;
		}
  }

  .video {
    display: grid;
    justify-items: center;

    width: 80%;
    margin-left: auto;
    margin-right: auto;

    iframe {
      width: 100%;
      height: 20vw;
    }
  }
}
